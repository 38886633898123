<template>
    <div v-show="isOpen" class="relative">
        <overlay v-bind:is-open.sync="overlayIsOpen"></overlay>
        <div
            class="absolute left-0 mt-1 min-w-full border border-gray-400 shadow-lg rounded bg-white"
            :class="{ 'z-20': isOpen }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Overlay from "./Overlay";

export default {
    components: { Overlay },

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            overlayIsOpen: this.isOpen,
        };
    },

    watch: {
        isOpen(newValue) {
            this.overlayIsOpen = newValue;
        },
        overlayIsOpen(newValue) {
            this.$emit("update:is-open", newValue);
        },
    },
};
</script>
