<template>
    <div class="flex w-5 h-5 justify-center items-center bg-transparent">
        <svg class="w-5 h-5 fill-current text-green-600" viewBox="0 0 128 128">
            <g>
                <path d="M64 9.75A54.25 54.25 0 009.75 64H0a64 64 0 01128 0h-9.75A54.25 54.25 0 0064 9.75z" />
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 64 64"
                    to="360 64 64"
                    dur="500ms"
                    repeatCount="indefinite"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {};
</script>
