export default class CitySearchResultNew {
    constructor(Name, Path, SoatoCode) {
        this.name = Name;
        this.path = Path;
        this.soatoCode = SoatoCode;
    }

    getName() {
        return this.name;
    }

    getPath() {
        return this.path;
    }

    getSoatoCode() {
        return this.soatoCode;
    }
}
