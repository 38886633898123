<template>
    <div>
        <red-header-with-background v-if="hasHugeRedHeader" :event="event" :has-bouncing-arrow="!isPromoPage">
            <template v-slot:header>
                <div class="flex flex-col" v-if="!isPromoPage">
                    <span>Анкета</span>
                    <span>для регистрации</span>
                    <span>на мероприятие:</span>
                </div>

                <div class="flex flex-col" v-if="isPromoPage">
                    <span>Проверка регистрации на мероприятие:</span>
                </div>
            </template>
        </red-header-with-background>

        <div class="flex justify-center items-center pt-4" v-if="waitingResponse">
            <spinner :is-visible="waitingResponse" class="text-green-500"></spinner>
            <span>Загружаем информацию о мероприятии</span>
        </div>

        <div class="flex flex-col justify-center items-center pt-4" v-if="hasError">
            <div class="flex justify-center items-center w-12 h-12 rounded-full border-red-600 border-2">
                <lightning class="w-6 h-6"/>
            </div>
            <span class="mt-2" v-text="errorMessage"/>
        </div>

        <!-- Стартовый экран -->
        <information
            v-if="currentStep === 'info'"
            :event="event"
            v-on:go-forward="currentStep = 'select-country'"
        />

        <!-- Выбор страны -->
        <select-country
                v-if="currentStep === 'select-country'"
                :country="country"
                :event="event"
                v-on:country-selected="updateCountry"
                v-on:go-forward="validateSelectCountryAndGo"
        />

        <!-- Компонент регистрации для выбранной страны -->
        <component v-if="currentStep === 'anketa'" :is="countryComponentName" :event="event"/>

        <!-- Интерфейс для промо персонала -->
        <promo v-if="currentStep === 'promo'" :event="event"/>

        <div class="flex flex-col pb-4" v-if="currentStep === 'find-event'">
            <div class="flex justify-center pt-6">
                <div class="px-3 text-sm text-red-600">
                    <a href="https://www.mars.com/global/policies/privacy/pp-russian" target="_blank">
                        Положение о конфиденциальности
                    </a>
                </div>
                <div class="px-3 text-sm text-red-600">
                    <a href="https://www.mars.com/global/policies/legal/ld-russian" target="_blank">
                        Правила использования сайта
                    </a>
                </div>
                <div class="px-3 text-sm text-red-600">
                    <a href="https://www.mars.com/accessibility-russian" target="_blank">
                        Доступность
                    </a>
                </div>
            </div>
            <div class="flex justify-center pt-6 text-gray-500 text-sm">
                © 2024
            </div>
        </div>
    </div>
</template>

<style src="./assets/css/tailwind.css"></style>

<script>
import RedHeaderWithBackground from "@/components/RedHeaderWithBackground";
import SelectCountry from "@/components/steps/SelectCountry";
import Information from "@/components/steps/Information";
import Spinner from "@/components/Spinner";
import Lightning from "@/components/icons/Lightning";
import createEvent from "@/helpers/createEvent";
import Belarus from "@/components/steps/belarus/Belarus.vue";

const World = () => import(/* webpackChunkName: "world" */ "@/components/steps/world/World");
const Promo = () => import(/* webpackChunkName: "promo" */ "@/components/steps/Promo");

export default {
    components: {
        Belarus,
        World,
        RedHeaderWithBackground,
        SelectCountry,
        Spinner,
        Lightning,
        Promo,
        Information,
    },

    data() {
        return {
            event: null,
            country: "belarus",
            currentStep: "find-event",
            waitingResponse: false, // Ожидаем ответ от API
            errorMessage: "",
        };
    },

    created() {
        this.fetchEvent();
    },

    computed: {
        countryComponentName() {
            switch (this.country) {
                case "belarus":
                    return "Belarus";
                default:
                    return "World";
            }
        },
        urlPathname() {
            return String(new URL(location.href).pathname).replace(
                process.env.VUE_APP_ENV === "production" ? "/" : "/anketa/",
                "",
            );
        },
        eventCode() {
            return this.isPromoPage ? this.urlPathname.replace("promo/", "") : this.urlPathname;
        },
        isPromoPage() {
            return this.urlPathname.startsWith("promo/");
        },
        hasError() {
            return this.errorMessage.length > 0;
        },
        hasHugeRedHeader() {
            return ["find-event", "info", "select-country", "promo"].includes(this.currentStep);
        },
        hasValidEventCode() {
            return this.eventCode.length > 0;
        },
    },

    methods: {
        fetchEvent() {
            if (!this.hasValidEventCode) {
                return;
            }
            this.waitingResponse = true;
            this.$http
                .get(`/api/v1/event/?code=${this.eventCode}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.VUE_APP_TEMPORARY_TOKEN}`,
                    },
                })
                .then(this.handleFetchEventResponse)
                .catch(this.handleFetchEventError)
                .finally(() => {
                    this.waitingResponse = false;
                });
        },

        handleFetchEventResponse(response) {
            this.saveEventAndGo(createEvent(response.data.data));
        },

        handleFetchEventError(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    this.errorMessage = "Мероприятие с таким кодом не найдено. Пожалуйста, проверьте адрес страницы.";
                    return;
                }
            }

            this.errorMessage = "Произошла исключительная ошибка. Пожалуйста, повторите запрос позднее.";
        },

        validateSelectCountryAndGo() {
            this.currentStep = "anketa";
        },

        saveEventAndGo(event) {
            this.event = event;

            if (Date.now() < event.getStartDate().getTime()) {
                this.errorMessage = `Регистрация на мероприятие начнётся ${event.getFormattedStartDate()}.`;
                return;
            }

            if (Date.now() > event.getEndDate().getTime()) {
                this.errorMessage = "Регистрация на мероприятие уже закончена.";
                return;
            }

            this.currentStep = this.isPromoPage ? "promo" : "info";
        },

        updateCountry(country) {
            this.country = country;
        },
    },
};
</script>
