<template>
    <div
        class="flex flex-col h-12 border"
        :class="{
            'border-black': !markRequired,
            'border-red-600': markRequired,
        }"
    >
        <label class="relative flex w-full h-full items-center justify-center">
            <input
                class="flex w-full h-full px-3 py-2 cursor-pointer focus:outline-none placeholder-gray-500"
                :placeholder="searchFieldPlaceholder"
                :class="{ 'z-20': isOpen }"
                v-model.trim="search"
                @input="startSearch"
                @click="checkResults"
                @keydown.down="scrollResults($event, 'down')"
                @keydown.up="scrollResults($event, 'up')"
                @keydown.enter.prevent="guessResult"
            />
            <loader v-show="isLoading" class="absolute right-0 mr-3 z-20 h-10" />
        </label>
        <results-list v-bind:is-open.sync="isOpen">
            <div
                class="flex flex-col"
                @keydown.down="scrollResults($event, 'down')"
                @keydown.up="scrollResults($event, 'up')"
            >
                <div v-if="moreResults" class="flex p-2 bg-orange-200 text-gray-600 text-xs rounded-t">
                    Уточните поиск - найдено результатов больше, чем показывается в списке.
                </div>
                <div v-if="emptyResult" class="flex p-2 bg-orange-200 text-gray-600 text-sm rounded">
                    Ничего похожего не найдено.
                </div>
                <div
                    v-if="somethingWrong"
                    class="flex p-2 bg-orange-100 text-orange-400 text-sm rounded"
                    v-text="errorMessage"
                ></div>
                <div class="flex flex-col flex-1 divide-y divide-gray-400 divide-dashed" v-show="hasResults">
                    <search-result-element
                        v-for="(result, index) in results"
                        :key="result.getSoatoCode()"
                        :name="result.getPath()"
                        :is-active="index === activeIndex"
                        @on-focus="activeIndex = index"
                        @on-hover="activeIndex = index"
                        @click="selectResult(index)"
                    />
                </div>
            </div>
        </results-list>
    </div>
</template>

<script>
import Loader from "@/components/icons/Loader";
import Popup from "@/components/Popup";
import SearchResultElement from "@/components/SearchResultElement";
import CitySearchResultNew from "@/models/citySearchResultNew.js";

let timeout;

export default {
    components: {
        Loader,
        "results-list": Popup,
        SearchResultElement,
    },

    model: {
        event: "updated",
    },

    props: {
        value: {
            type: CitySearchResultNew,
            default: null,
        },
        markRequired: {
            type: Boolean,
            default: false,
        },
        searchFieldPlaceholder: {
            type: String,
            default: "Начните вводить",
        },
    },

    created() {
        if (this.value) {
            this.results = [this.value];
            this.activeIndex = 0;
            this.search = this.value.getPath();
        }
    },

    data() {
        return {
            errorMessage: "", // Текст сообщения об ошибке
            search: "", // Поисковый запрос
            results: [], // Результаты поиска
            isOpen: false, // Всплывашка с результатами открыта
            isLoading: false, // Ожидаем ответ
            activeIndex: null, // Индекс активного элемента в результатах поиска
        };
    },

    watch: {
        search(newValue) {
            if (newValue.length === 0) {
                this.reset();
            }
        },
    },

    computed: {
        emptyResult() {
            return this.search.length > 0 && this.results.length === 0;
        },
        hasResults() {
            return this.results.length > 0;
        },
        moreResults() {
            return this.results.length > 6;
        },
        somethingWrong() {
            return this.errorMessage.length > 0;
        },
    },

    methods: {
        setResult(newValue) {
            if (newValue) {
                this.search = newValue.getPath();
            }
            this.$emit("updated", newValue);
        },

        reset() {
            this.setResult(null);
            this.activeIndex = null;
            this.results = [];
            this.isOpen = false;
        },

        startSearch() {
            this.errorMessage = "";
            if (this.search.length === 0) {
                this.results = [];
            }
            clearTimeout(timeout);
            if (this.search.length > 1) {
                timeout = setTimeout(() => {
                    this.fetchBelarusResults(this.search);
                }, 300);
            }
        },

        fetchBelarusResults(query) {
            this.isLoading = true;

            this.$http
                .get(`${process.env.VUE_APP_API_HOST}/api/v1/belarus/cities`, {
                    params: {
                        search: query,
                        perPage: 7,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.VUE_APP_API_HOST_TOKEN}`,
                    },
                })
                .then(this.handleBelarusResponse)
                .catch(this.handleError)
                .finally(() => (this.isLoading = false));
        },

        handleBelarusResponse(response) {
            this.activeIndex = 0;
            this.results = response.data.data.map(
                (el) => new CitySearchResultNew(el["name"], el["path"], el["soato_code"])
            );
            this.isOpen = true;
        },

        handleError() {
            this.errorMessage = "Ошибка соединения с сервером.";
        },

        checkResults() {
            if (this.hasResults) {
                this.activeIndex = this.value
                    ? this.results.findIndex((result) => result.getSoatoCode() === this.value.getSoatoCode())
                    : 0;
                this.isOpen = true;
            }
        },

        scrollResults(e, direction) {
            if (!this.hasResults) {
                return;
            }

            e.preventDefault();

            if (direction === "down") {
                this.activeIndex = this.activeIndex === this.results.length - 1 ? 0 : this.activeIndex + 1;
            }

            if (direction === "up") {
                this.activeIndex = this.activeIndex === 0 ? this.results.length - 1 : this.activeIndex - 1;
            }
        },

        guessResult() {
            if (!this.isOpen) {
                this.checkResults();
                return;
            }
            if (this.results.length === 0) {
                return;
            }

            if (this.activeIndex === null) {
                return;
            }

            this.selectResult(this.activeIndex);
        },

        selectResult(index) {
            this.setResult(this.results[index]);
            this.isOpen = false;
        },
    },
};
</script>
