import createGroup from "@/helpers/createGroup.js";

export default class Event {
    constructor(id, name, isOnline, isDarkMode, startDate, endDate, anketaUrl, participants, group) {
        this.id = id;
        this.name = name;
        this.online = isOnline;
        this.darkMode = isDarkMode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.anketaUrl = anketaUrl;
        this.participants = participants;
        this.group = createGroup(group);
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    isOnline() {
        return Boolean(this.online);
    }

    isDarkMode() {
        return Boolean(this.darkMode);
    }

    getStartDate() {
        return new Date(this.startDate);
    }

    getFormattedStartDate() {
        return this.getStartDate().toLocaleDateString("ru-RU");
    }

    getEndDate() {
        return new Date(this.endDate);
    }

    getAnketaUrl() {
        return this.anketaUrl;
    }

    getParticipants() {
        return this.participants;
    }
}
