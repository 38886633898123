<template>
    <!-- Просто красная шапка -->
    <div class="flex bg-red-600 py-5 justify-center items-center divide-x divide-white">
        <!-- Логотип -->
        <div
            v-if="!isDarkMode"
            class="flex h-24 justify-center items-center pr-8">
            <div class="flex w-20 h-20 rounded-full bg-white justify-center items-center shadow-2xl pl-1">
                <a :href="eventUrl"><img class="w-16" src="@/assets/images/logo.png" alt="logo" /></a>
            </div>
        </div>

        <!-- Заголовок -->
        <div class="flex h-24 pl-8">
            <div class="flex flex-col text-white leading-tight text-left justify-center items-center">
                <div class="flex flex-col text-lg font-semibold">
                    <span>Анкета</span>
                    <span>для регистрации</span>
                    <span>на мероприятие:</span>
                    <span class="mt-2" v-text="eventName"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        eventName: {
            type: String,
            required: true,
        },
        eventUrl: {
            type: String,
            required: true,
        },
        isDarkMode: {
            type: Boolean,
            default: true
        }
    },
};
</script>
