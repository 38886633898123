<template>
    <div class="flex justify-start items-center text-gray-500 space-x-1">
        <exclamation v-if="hasExclamationIcon" />
        <shield v-if="hasShieldIcon" />
        <span class="text-sm leading-tight font-semibold" v-text="exclamationText" v-if="hasExclamationText" />
        <span class="text-sm leading-tight" v-text="comment" />
    </div>
</template>

<script>
import Exclamation from "@/components/icons/Exclamation.vue";
import Shield from "@/components/icons/Shield.vue";

export default {
    components: {
        Exclamation,
        Shield,
    },

    props: {
        comment: {
            type: String,
            required: true,
        },
        exclamationText: {
            type: String,
            default: "",
        },
        hasExclamationIcon: {
            type: Boolean,
            default: false,
        },
        hasShieldIcon: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasExclamationText() {
            return this.exclamationText.length > 0;
        },
    },
};
</script>
