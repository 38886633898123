<template>
    <button
            v-show="isOpen"
            tabindex="-1"
            @click.prevent="closePopup"
            class="fixed inset-0 w-full h-full cursor-default flex items-start"
            :class="{'z-20': isOpen, 'bg-transparent': transparent, 'bg-gray-900 opacity-75': !transparent}"
    >
    </button>
</template>

<script>
    export default {
        props: {
            isOpen: {
                type: Boolean,
                default: false,
            },

            transparent: {
                type: Boolean,
                default: true,
            }
        },

        methods: {
            closePopup() {
                this.$emit('update:is-open', false)
            }
        },

        created() {
            const handleEscape = (e) => {
                if (e.key === 'Esc' || e.key ==='Escape') {
                    this.closePopup()
                }
            }

            document.addEventListener('keydown', handleEscape)

            this.$once('hook:beforeDestroy', () => {
                document.removeEventListener('keydown', handleEscape)
            })
        },
    }
</script>
