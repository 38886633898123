<template>
    <div>
        <div class="flex flex-col -mb-9 bg-red-600">
            <hr class="m-auto" style="border-top: 1px solid white; width: 90%;" />
            <div class="flex flex-col space-y-8 m-auto pt-15 px-7 text-white md:text-xl text-lg">
                <div class="inline">
                    <strong class="mr-2">Шаг&nbsp;1</strong>&nbsp;| Заполните анкету
                </div>

                <div class="inline">
                    <strong class="mr-2">Шаг&nbsp;2</strong>&nbsp;| Получите SMS-код для регистрации
                </div>

                <div class="inline">
                    <strong class="mr-2">Шаг&nbsp;3</strong>&nbsp;| Введите SMS-код в&nbsp;поле&nbsp;<img src="@/assets/images/sms-input.svg" alt="sms-input" class="ml-2 inline" />
                </div>

                <div class="inline">
                    <strong class="mr-2">Шаг&nbsp;4</strong>&nbsp;| Нажмите кнопку &laquo;Завершить регистрацию&raquo;
                </div>
            </div>
        </div>

        <red-footer v-on:go-forward="$emit('go-forward')" textGoForward="Всё понятно, начинаем" :isTextPressToGoForward="false" />
    </div>
</template>

<script>
import RedFooter from "@/components/RedFooter";
import Event from "@/models/event";

export default {
    components: {
        RedFooter,
    },

    props: {
        event: {
            type: Event,
            required: true,
        },
    },
};
</script>
