<template>
    <div class="flex w-5 h-5 justify-center items-center text-white">
        <svg class="w-5 h-5 stroke-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 19L3 12M3 12L10 5M3 12L21 12"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </div>
</template>

<script>
export default {};
</script>
