<template>
    <div class="flex text-gray-500 text-xl leading-none">
        <span v-text="step"></span>
        <span class="ml-6" v-text="name"></span>
    </div>
</template>

<script>
export default {
    props: {
        step: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
};
</script>
