<template>
    <div class="flex flex-col">
        <div class="flex justify-start items-center">
            <span class="text-lg leading-none select-none" v-text="sectionName"></span>
            <lightning v-if="markRequired" class="ml-2"></lightning>
        </div>
        <div v-if="hasDescription" class="flex mt-2">
            <span
                class="text-sm leading-none select-none"
                :class="markRequired ? 'text-red-600' : 'text-gray-500'"
                v-text="comment"
            ></span>
        </div>
    </div>
</template>

<script>
import Lightning from "./icons/Lightning";

export default {
    components: {
        Lightning,
    },

    props: {
        sectionName: {
            type: String,
            required: true,
        },
        markRequired: {
            type: Boolean,
            default: false,
        },
        comment: {
            type: String,
            default: "",
        },
    },

    computed: {
        hasDescription() {
            return this.comment.length > 0;
        },
    },
};
</script>
