<template>
    <div class="flex w-4 h-4">
        <component :is="isChecked ? 'CircleCheckmarkChecked' : 'CircleCheckmarkUnchecked'"></component>
    </div>
</template>

<script>
import CircleCheckmarkUnchecked from "@/components/icons/CircleCheckmarkUnchecked";
import CircleCheckmarkChecked from "@/components/icons/CircleCheckmarkChecked";

export default {
    components: {
        CircleCheckmarkUnchecked,
        CircleCheckmarkChecked,
    },
    props: {
        isChecked: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
