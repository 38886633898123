<template>
    <div class="mt-6">
        <div class="flex flex-col px-4 space-y-6">
            <breadcrumbs step="1 / 3" name="Регистрация"/>

            <!-- Выбор города -->
            <div class="flex flex-col space-y-3">
                <section-heading section-name="ГОРОД ИЛИ ДРУГОЙ НАСЕЛЁННЫЙ ПУНКТ" :mark-required="markCityRequired"/>
                <select-city :mark-required="markCityRequired" v-model="userCity"/>
            </div>

            <!-- Выбор мобильного телефона -->
            <div class="flex flex-col space-y-3">
                <section-heading
                        section-name="УКАЖИТЕ ВАШ НОМЕР МОБИЛЬНОГО ТЕЛЕФОНА"
                        :mark-required="markPhoneRequired"
                />

                <section-comment
                        comment="Номер нужен только для получения кода и завершения регистрации."
                        :has-shield-icon="true"
                        exclamation-text="Это безопасно."
                />

                <div class="flex text-black text-lg">
                    <div class="flex flex-col w-full">
                        <div class="flex">
                            <div class="flex justify-center items-center">
                                <span class="text-gray-500 mr-3">+375</span>
                            </div>
                            <div class="flex flex-1">
                                <label class="flex w-full items-center justify-center">
                                    <select-phone
                                            class="flex w-full h-12 border px-3 py-2 cursor-pointer focus:outline-none placeholder-gray-500"
                                            :class="{
                                            'border-black': !markPhoneRequired,
                                            'border-red-600': markPhoneRequired,
                                        }"
                                            type="tel"
                                            :mask="'(##) ###-##-##'"
                                            placeholder="(**) *** ** **"
                                            v-model="phoneWithoutPrefix"
                                    />
                                </label>
                            </div>
                        </div>

                        <div
                            v-if="hasErrorMessage"
                            class="text-red-600"
                        >
                            {{ userErrorMessage }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <red-footer v-on:go-forward="$emit('go-forward')" :has-errors="hasRequiredFieldsMissing"/>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import RedFooter from "@/components/RedFooter";
import { TheMask } from "vue-the-mask";
import SectionHeading from "@/components/SectionHeading";
import SectionComment from "@/components/SectionComment";
import BelarusCitySearch from "@/components/BelarusCitySearch.vue";
import CitySearchResultNew from "@/models/citySearchResultNew.js";

export default {
    components: {
        Breadcrumbs,
        RedFooter,
        "select-city": BelarusCitySearch,
        "select-phone": TheMask,
        SectionHeading,
        SectionComment,
    },

    props: {
        city: {
            type: CitySearchResultNew,
            default: null,
        },
        phone: {
            type: String,
            default: "",
        },
        markCityRequired: {
            type: Boolean,
            default: false,
        },
        markPhoneRequired: {
            type: Boolean,
            default: false,
        },
        hasRequiredFieldsMissing: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            phoneWithoutPrefix: null,
            userCity: this.city,
            userErrorMessage: this.errorMessage,
        };
    },

    created() {
        this.phoneWithoutPrefix = this.parsePhoneNumber(this.phone);
    },

    watch: {
        userPhone(newValue) {
            this.$emit("update:errorMessage", "");

            if (this.phoneIsValid) {
                this.$emit("update:phone", newValue);
                return;
            }

            this.$emit("update:phone", "");
        },

        userCity(newValue) {
            this.$emit("update:city", newValue);
        },

        errorMessage(newValue) {
            this.userErrorMessage = newValue;
        },
    },

    computed: {
        userPhone() {
            return "+375" + this.phoneWithoutPrefix;
        },

        phoneIsValid() {
            return this.userPhone.length === 13;
        },

        hasErrorMessage() {
            return this.userErrorMessage.length > 1;
        }
    },

    methods: {
        parsePhoneNumber(phone) {
            if (phone === null) {
                return "";
            }

            phone = phone.replace("+", "");

            if (phone.startsWith("375")) {
                return phone.substr(3);
            }

            return "";
        },
    },
};
</script>
