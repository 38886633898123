<template>
    <div class="mt-6">
        <div class="flex flex-col px-4 space-y-6">
            <breadcrumbs step="1 / 3" name="Регистрация" />

            <div class="flex flex-col space-y-3">
                <!-- Выбор страны -->
                <section-heading section-name="СТРАНА" />

                <belarus
                    :is-checked="country === 'belarus'"
                    @click="selectCountry('belarus')"
                    country-name="Беларусь"
                    comment="Вы гражданин/нка Республики Беларусь"
                >
                    <template v-slot:active>
                        <img class="h-8" src="@/assets/images/flags/belarus-active.svg" alt="belarus" />
                    </template>
                    <template v-slot:inactive>
                        <img class="h-8" src="@/assets/images/flags/belarus-not-active.svg" alt="belarus" />
                    </template>
                </belarus>

                <world
                    :is-checked="country === 'world'"
                    @click="selectCountry('world')"
                    country-name="Другая страна"
                    comment="Вы гражданин/нка другого государства"
                >
                    <template v-slot:active>
                        <img class="h-8" src="@/assets/images/flags/world-active.svg" alt="world" />
                    </template>
                    <template v-slot:inactive>
                        <img class="h-8" src="@/assets/images/flags/world-not-active.svg" alt="world" />
                    </template>
                </world>
            </div>
        </div>

        <red-footer v-on:go-forward="$emit('go-forward')" />
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import RedFooter from "@/components/RedFooter";
import SectionHeading from "@/components/SectionHeading";
import CountryButton from "@/components/CountryButton";
import Event from "@/models/event";

export default {
    components: {
        Breadcrumbs,
        RedFooter,
        SectionHeading,
        world: CountryButton,
        belarus: CountryButton,
    },

    props: {
        event: {
            type: Event,
            required: true,
        },
        country: {
            type: String,
            required: true,
        },
    },

    methods: {
        selectCountry(country) {
            this.$emit("country-selected", country);
        },
    },
};
</script>
