<template>
    <div
        class="flex border p-4 items-center"
        :class="{
            'border-black': isChecked,
            'border-gray-200': !isChecked,
        }"
        @mouseenter="isHover = true"
        @mouseleave="isHover = false"
        @click="$emit('click')"
    >
        <circle-checkmark :is-checked="isChecked"></circle-checkmark>
        <div class="flex flex-col flex-1 px-4 space-y-3">
            <span class="leading-none" v-text="countryName"></span>
            <span class="text-gray-500 leading-tight" v-text="comment"></span>
        </div>
        <div class="flex w-12 h-8 items-center justify-center">
            <slot v-if="iconNeedsColorLayer" name="active"></slot>
            <slot v-if="!iconNeedsColorLayer" name="inactive"></slot>
        </div>
    </div>
</template>

<script>
import CircleCheckmark from "@/components/CircleCheckmark";

export default {
    components: {
        CircleCheckmark,
    },

    props: {
        isChecked: {
            type: Boolean,
            default: false,
        },
        countryName: {
            type: String,
            required: true,
        },
        comment: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isHover: false,
        };
    },

    computed: {
        iconNeedsColorLayer() {
            if (this.isChecked) {
                return true;
            }

            return this.isHover;
        },
    },
};
</script>
