import Vue from "vue";
import Axios from "axios";
import App from "./App.vue";

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.withCredentials = true;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_HOST;
Vue.prototype.$http.defaults.headers.common['Accept'] = 'application/json';

Vue.config.productionTip = false;

Vue.directive("filter", {
    bind: function (el, binding) {
        let inputHandler = function (e) {
            const ch = String(e.key);
            const re = new RegExp(binding.value);
            if (!ch.match(re)) {
                e.preventDefault();
            }
        };
        el.addEventListener("keypress", inputHandler);
    },
});

new Vue({
    render: (h) => h(App),
}).$mount("#app");
