import Event from "@/models/event";

export default function (input) {
    return new Event(
        "id" in input ? input.id : null,
        "name" in input ? input.name : null,
        "is_online" in input ? input.is_online : null,
        "is_dark_mode" in input ? input.is_dark_mode : null,
        "start_date" in input ? input.start_date : null,
        "end_date" in input ? input.end_date : null,
        "anketa_url" in input ? input.anketa_url : null,
        "participants" in input ? input.participants : null,
        "group" in input ? input.group : {}
    );
}
