<template>
    <div class="flex flex-col">
        <div class="flex justify-center items-center leading-snug px-4 mt-6 mb-3">
            <div v-if="hasErrors" class="flex justify-center items-center space-x-3">
                <lightning/>
                <span class="text-red-600 select-none">Пожалуйста, заполните отмеченные поля.</span>
            </div>

            <div v-if="!hasErrors && isTextPressToGoForward" class="flex justify-center items-center space-x-3 text-gray-500">
                <checkmark/>
                <span class="select-none">Нажмите продолжить</span>
            </div>
        </div>

        <!-- Красная полоска с ссылками навигации -->
        <div class="flex flex-col">
            <div class="flex bg-red-600 items-center px-4" :class="[formNeedsStepBack ? 'justify-between' : 'justify-center', !isTextPressToGoForward ? 'h-36' : 'h-16']">
                <div v-if="formNeedsStepBack" class="flex">
                    <div class="flex justify-center items-center">
                        <arrow-left class="mr-2"/>
                        <span
                                class="text-white font-medium hover:underline cursor-pointer select-none"
                                @click="$emit('go-back')"
                                v-text="stepBackName"
                        />
                    </div>
                </div>

                <div
                    class="flex justify-center items-center cursor-pointer select-none"
                    :class="{ 'red-footer-button-outline': !isTextPressToGoForward, 'pointer-events-none': disableGoForward }"
                    @click="$emit('go-forward')"
                >
                    <span class="text-white font-medium text-center">
                        {{ textGoForward }}
                    </span>
                    <arrow-right class="ml-2"/>
                </div>
            </div>
            <div class="flex flex-col pb-4">
                <div class="flex justify-center pt-6 flex-col md:flex-row text-center">
                    <div class="px-3 text-sm text-red-600">
                        <a href="https://www.mars.com/global/policies/privacy/pp-russian" target="_blank">
                            Положение о конфиденциальности
                        </a>
                    </div>
                    <div class="px-3 text-sm text-red-600">
                        <a href="https://www.mars.com/global/policies/legal/ld-russian" target="_blank">
                            Правила использования сайта
                        </a>
                    </div>
                    <div class="px-3 text-sm text-red-600">
                        <a href="https://www.mars.com/accessibility-russian" target="_blank">
                            Доступность
                        </a>
                    </div>
                </div>
                <div class="flex justify-center pt-6 text-gray-500 text-sm">
                    © 2024
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Lightning from "@/components/icons/Lightning";
import Checkmark from "@/components/icons/Checkmark";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowLeft from "@/components/icons/ArrowLeft";

export default {
    components: {
        Lightning,
        Checkmark,
        ArrowRight,
        ArrowLeft,
    },

    props: {
        stepBackName: {
            type: String,
            default: "",
        },
        hasErrors: {
            type: Boolean,
            default: false,
        },
        disableGoForward: {
            type: Boolean,
            default: false,
        },
        textGoForward: {
            type: String,
            default: "Продолжить",
        },
        isTextPressToGoForward: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        formNeedsStepBack() {
            return this.stepBackName.length > 0;
        },
    },
};
</script>

<style scoped>
.red-footer-button-outline {
    border-radius: 20px;
    border: 1px solid white;
    padding: 12px 5px;
    max-width: 90%;
    width: 100%;

    @media (max-width: 768px) {
        max-width: 100%;
    }
}
</style>
