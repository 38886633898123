<template>
    <button
            type="button"
            class="flex p-2 focus:outline-none focus:bg-blue-100"
            :class="{'bg-blue-100' : isActive}"
            @focus="$emit('on-focus')"
            @click.prevent="$emit('click')"
            @mouseenter="$emit('on-hover')"
    >
        <span class="flex text-left" v-text="name"></span>
    </button>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            isActive: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
