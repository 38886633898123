<template>
    <div class="flex w-4 h-4 justify-center items-center">
        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667">
            <path
                d="M213.333,106.667c-58.88,0-106.667,47.787-106.667,106.667S154.453,320,213.333,320S320,272.213,320,213.333
				S272.213,106.667,213.333,106.667z"
            />
            <path
                d="M213.333,0C95.467,0,0,95.467,0,213.333s95.467,213.333,213.333,213.333S426.667,331.2,426.667,213.333
				S331.2,0,213.333,0z M213.333,384c-94.293,0-170.667-76.373-170.667-170.667S119.04,42.667,213.333,42.667
				S384,119.04,384,213.333S307.627,384,213.333,384z"
            />
        </svg>
    </div>
</template>

<script>
export default {};
</script>
